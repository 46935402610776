import { getOrder } from '@/api/order/order'
import { allFileApproval } from '@/api/order/orderfile'

const order = {
  state: {
    isManage: false,
    pageType: '',
    categoryId: null,
    orderId: 0,
    orderBean: {},
    beginUpdateMap: new Date(),
    mapType: '',
    stations: [],
    warningStations: [], // 醒目标注的站点集合
    searchCondition: {},
    beginUpdatePurchase: new Date(),
    defaultProduct: null,
    bottomProduct: null,
    showAssets: [],
    checkSchedules: [],
    purchaseDuration: 0, // 电子屏采购秒数
    purchasePeriod: 0, // 电子屏采购规格
    leftReload: new Date(),
    detailReload: new Date(),

    clickGetMapStationId: null,
    clickGeoMapPurchase: new Date(),
    approvalFileList: [],
    approvalFile: {}
  },
  mutations: {
    set_order_isManage (state, data) {
      state.isManage = data
    },
    set_order_pageType (state, data) {
      state.pageType = data
    },
    set_order_categoryId (state, data) {
      state.categoryId = data
    },
    set_order_bean (state, data) {
      state.orderBean = data
    },
    set_order_ID (state, data) {
      state.orderId = data
    },
    set_order_update_map (state, data) {
      state.beginUpdateMap = data
    },
    set_order_map_type (state, data) {
      state.mapType = data
    },
    set_order_stations (state, data) {
      state.stations = data
    },
    set_order_warning_stations (state, data) {
      state.warningStations = data
    },
    set_order_search_condition (state, data) {
      state.searchCondition = data
    },
    set_order_update_purchase (state, data) {
      state.beginUpdatePurchase = data
    },
    set_order_defaultProduct (state, data) {
      state.defaultProduct = data
    },
    set_order_bottom_product (state, data) {
      state.bottomProduct = data
    },
    set_order_show_assets (state, data) {
      state.showAssets = data
    },
    set_order_check_schedule (state, data) {
      state.checkSchedules = data
    },
    set_order_purchase_duration (state, data) {
      state.purchaseDuration = data
    },
    set_order_purchase_period (state, data) {
      state.purchasePeriod = data
    },
    set_order_update_left (state, data) {
      state.leftReload = data
    },
    set_order_detail_reload (state, data) {
      state.detailReload = data
    },
    set_order_update_getMapPurchase (state, data) {
      state.clickGeoMapPurchase = data
    },
    set_order_update_getMapStationId (state, data) {
      state.clickGetMapStationId = data
    },
    set_approval_file_list (state, data) {
      state.approvalFileList = data
    },
    set_approval_file (state, data) {
      state.approvalFile = data
    }
  },
  getters: {

  },
  actions: {
    // 获取订单基础信息
    getOrderBaseInfo ({ state, commit }, query) {
      return new Promise((resolve) => {
        getOrder(query).then(res => {
          commit('set_order_bean', res)
          // commit('set_order_ID', res.id)
          resolve(res)
        })
      })
    },
    /**
         * 获取文件审批历史记录
         * @param {Object} param0
         * @param {Object} data
         */
    getApprovalFileHistory ({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        allFileApproval({ orderId: data.orderId }).then(res => {
          commit('set_approval_file_list', res)
          // state.approvalFileList = res
          // if (res && !res.errcode && res.length > 0) {
          //   // state.approvalFile = res[0]
          //   commit('set_approval_file', res[0])
          // }
          resolve(res)
        })
      })
    }
  }
}

export default order
