<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent"></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent"></component>
      </div>
      <div slot="bottom_box_content">
        <component v-show="curBottomComponent === 'OrderList'" :is="'OrderList'"></component>
        <component v-show="curBottomComponent !== 'OrderList'" :is="curBottomComponent"></component>
      </div>
      <div slot="bg_map">
        <component :is="curMapComponent"></component>
      </div>

    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
// 引入store模块
import orderStoreModule from '@/store/modules/order'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    centerComponent: () => import('@/components/order/Center'),
    // 左侧组件
    left_OrderCreate: () => import('@/components/order/left/Create_Manage'),
    left_OrderEdit: () => import('@/components/order/left/Edit2'),
    // 底部组件
    OrderList: () => import('@/components/order/bottom/List_Manage'),
    bottom_OrderEdit: () => import('@/components/order/bottom/Edit'),
    bottom_OrderDetail: () => import('@/components/order/bottom/Detail'),
    // 右侧组件
    right_OrderEdit: () => import('@/components/order/right/Edit'),
    right_OrderDetail: () => import('@/components/order/right/Detail')
  },
  created () {
    // 注册动态store模块
    if (!this.$store.hasModule('order')) {
      this.$store.registerModule('order', orderStoreModule)
      this.$store.commit('set_order_isManage', true)
    }
  },
  mounted () {
    this.$store.commit('set_order_pageType', 'list')
  },
  computed: {
    pageType () {
      if (this.$store.state.order) {
        return this.$store.state.order.pageType
      } else {
        return ''
      }
    }
  },
  methods: {
    initTagEvent () {
    // 注册头部事件
      this.setLinkRouterMaps(['orderList', () => {
        this.$store.commit('set_order_pageType', 'list')
      }])
      this.setLinkRouterMaps(['orderDetail', () => { }])
      this.setLinkRouterMaps(['orderEdit', () => { }])
    }
  },
  watch: {
    pageType (val) {
      if (val === 'edit') {
        this.setShowRightBlock(true)
        this.setRightComponent('right_OrderEdit')
        this.setShowLeftBlock(true)
        this.setLeftComponent('left_OrderEdit')
        this.setBottomHeight('300px')
        this.setBottomComponent('bottom_OrderEdit')
        this.setMapComponent('centerComponent')

        this.setLinkTagArray({ key: 'orderList', value: '订单列表' })
        this.setLinkTagArray({ key: 'orderEdit', value: '采购清单' })
        this.setActivedTagName('orderEdit')
      } else if (val === 'detail') {
        this.setShowRightBlock(true)
        this.setRightComponent('right_OrderDetail')
        this.setBottomHeight('90%')
        this.setBottomComponent('bottom_OrderDetail')
        this.setMapComponent('centerComponent')

        this.setLinkTagArray({ key: 'orderList', value: '订单列表' })
        this.setLinkTagArray({ key: 'orderDetail', value: '订单详情' })
        this.setActivedTagName('orderDetail')
      } else if (val === 'create') {
        this.setShowLeftBlock(true)
        this.setLeftComponent('left_OrderCreate')
        this.setBottomHeight('90%')
        // this.setShowRightBlock(true)
        // this.setRightComponent('salerOverviewComponent')
        this.setMapComponent('')
        this.tagList = [
          { key: 1, value: '我的采购清单' }
        ]
        this.tagType = 1
      } else {
        this.setShowLeftBlock(false)
        this.setShowRightBlock(false)
        this.setMapComponent('')
        this.setBottomHeight('90%')
        this.setBottomComponent('OrderList')

        this.setLinkTagArray()
      }
      this.initTagEvent()
    }
  },
  destroyed () {
    // 卸载动态store模块
    this.$store.unregisterModule('order')
    this.setShowLeftBlock(true)
    this.setShowMap(true)
    this.setShowRightBlock(true)
    this.setBottomHeight('300px')
    this.setLeftComponent('')
    this.setRightComponent('')
    this.setBottomComponent('')
    this.setMapComponent('')
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  }
}
</script>
